import { HTTP } from '../../axios/axios'
let data

async function getAll (lang, page) {
  try {
    const res = await HTTP.get('/list-content', { params: { page }, headers: { 'accept-language': lang } })
    data = res.data
    return data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (listContent, lang) {
  listContent.lang = lang
  listContent.page = data[0].page
  return await HTTP.post('/list-content', listContent)
}
async function update (listContent, id) {
  return await HTTP.patch('/list-content/' + id, listContent)
}
async function deleteOne (listContent) {
  return await HTTP.delete('/list-content/' + listContent._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/list-content/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
